<template>
  <div class="pages">
    <h3>关于我们</h3>
    <div class="conent">
        <h1 style="font-size:32px;text-align:center;margin-bottom:10px">关于我们</h1>
      <!-- <h2>发布时间：2016-03-21 16:26:44 浏览次数：124636 次 【字体：小 大】</h2> -->
      <p>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云南省建筑技工学校始建于1984年，隶属云南建工集团有限公司，是一所由企业自办、面向全省开展建筑业中、高级技术人才培训的中等职业学校，是集职业培训、安全教育、职业技能鉴定、职业指导、劳务人才派遣、劳务工程分包为一体的多功能综合培训基地。根据集团发展需要，2007年10月，成立云南建工职业培训中心，实行“一套班子、两块牌子”管理模式。在长期的办学实践中，学校牢固树立“以市场为导向，立足集团，面向社会”的办学理念，全面贯彻职业教育法，持之以恒地开展集团内和社会的技术人才培训，牢固树立为经济和社会发展服务、培养高素质技能人才服务的指导思想，积极为云南省建筑行业的健康发展提供优质人才资源支持。
      </p>
    </div>
    <div class="contact">
      <el-row class="bj" :gutter="60">
        <el-col :span="8">报名咨询电话1：0871-65640266</el-col>
        <el-col :span="8">报名咨询电话2：0871-63136387</el-col>
        <el-col :span="8">报名咨询电话3：0871-65891876</el-col>
        
      </el-row>
    </div>
  </div>
</template>
<script>
import {news} from '@/api/news'
export default {
  data(){
    return {
      data:{},
    }
  },
  created(){
    
  },
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    min-height: 240px;
    margin: 30px 57px 0 60px;
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 41px;
      color: #333333;
      margin-bottom: 30px;
    }
    p {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #666666;
    }
  }
  .contact {
    padding: 60px;
    .bj {
      background-color: #f9f9f9;
      div {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 34px;
        color: #717171;
        margin: 30px 0;
      }
    }
  }
}
</style>